.entity-basic-details {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.entity-basic-details-icon-toggle-group {
  width: 100%;
  height: auto;
  gap: 8px 3px;
}

.entity-basic-details-modal-footer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 12px;
}
