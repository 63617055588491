.main-page {
  margin: 2em;
  flex: 2;
}

.list {
  width: 75%;
}

.sidebar {
  margin: 2em;
  flex: 1;
}
