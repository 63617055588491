.data-container {
  background: #f5f7f9;
  /* position: absolute; */
  /* text-align: center; */
  max-height: calc(100vh - 323px);
  overflow-y: auto;
  width: 100%;
  height: auto;
  margin-top: 32px;
  padding: 26px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.data-container--field {
  /* text-align: left; */
  color: #12344d;
  gap: 16px;
  max-width: 100%;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
}

.field-key {
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  box-sizing: border-box;
  width: 40%;
  max-width: 40%;
  margin: 0;
  pointer-events: none;
  user-select: none;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.field-value {
  flex-grow: 1;
  text-align: left;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  box-sizing: border-box;
  width: 60%;
  max-width: 60%;
  margin: 0;
  pointer-events: none;
  user-select: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.link-loading-spinner {
  height: 250px;
}
