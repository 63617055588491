.details-section {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 24px 40px 40px;
}

.back-button {
  margin-left: 4px;
  line-height: 20px;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
}
