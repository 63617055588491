.details-card {
  box-sizing: border-box;
  min-height: 100px;
  margin-top: 1em;
  padding-bottom: 1em;
  background: #ffffff;
  border: 1px solid #cfd7df;
  border-radius: 6px;
}

.card-header {
  width: 100%;
  height: 90px;
  box-sizing: border-box;
  padding: 0 28px 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebeff3;
  gap: 15px;
}

.header-title {
  font-size: 24px;
  font-weight: bold;
  color: #12344d;
  flex: 1;
  width: auto;
  line-height: 30px;
  pointer-events: none;
  user-select: none;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.header-actions {
  width: fit-content;
  max-width: fit-content;
}

.content {
  width: 100%;
  box-sizing: border-box;
  padding: 0 28px 0 40px;
}

.field {
  width: 100%;
  padding: 24px 0;
  text-align: left;
  display: flex;
  font-size: 14px;
  color: #12344d;
  box-sizing: border-box;
  gap: 16px;
}

.field-key {
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  box-sizing: border-box;
  width: 40%;
}

.field-value {
  flex-grow: 1;
  font-weight: 600;
  text-align: left;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  box-sizing: border-box;
  width: 60%;
}

.label-font {
  color: #576c7d;
}

.link-text-style {
  color: #2c5cc5;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}
