.container {
  height: calc(100vh - 48px);
  width: calc(100% - 320px);
  overflow-y: auto;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 2px 3px #ebeff3;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
