.header {
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid rgba(207, 215, 223, 0.4);
  box-shadow: 0px 2px 2px rgba(209, 209, 209, 0.4);
  border-radius: 4px;
}

.header-left-container {
  max-width: calc(100% - 300px);
  width: auto;
  height: auto;
  padding-right: 90px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 2 1 80%;
  gap: 12px;
}

.header-icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header-name-container {
  max-width: calc(100% - 24px - 12px);
  width: calc(100% - 24px - 12px);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  gap: 8px;
}

.header-name {
  max-width: 100%;
  width: auto;
  font-size: 20px;
  line-height: 24px;
  pointer-events: none;
  user-select: none;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.header-description {
  max-width: 100%;
  width: auto;
  font-size: 14px;
  line-height: 20px;
  pointer-events: none;
  user-select: none;
  text-align: left;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

.header-right-container {
  max-width: 300px;
  width: auto;
  height: auto;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex: 1 1 20%;
  gap: 10px;
}
