:root {
  --elephant: #183247;
  --smoke: #f3f5f7;
  --milk: #fff;
  --smoke10: #f7f9fa;
  --smoke700: #475867;
  --smoke600: #576c7d;
  --jungle: #00a886;
  --azure: #2c5cc5;
  --persimmon: #e43538;
  --casablanca: #e86f25;
  --sidebar-bg: #264966;
  --color-casablanca-50: #fef1e1;
  --color-jungle-50: #e0f5f1;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, oxygen, ubuntu, cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --fw-font-family: -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, oxygen, ubuntu, cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #ebeff3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* product requirement to display the toast message on top of modal, confirmed by COE team */
.fw-toast-stack {
  z-index: 1000 !important;
}
