.blank-state {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--elephant);
  text-align: center;
}

.blank-state-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blank-state-container-actions {
  padding: 0.5em 3em;
  justify-content: center;
  display: flex;
}

.blank-state-container-actions > fw-button {
  margin: 0 5px;
}

.blank-state-container-heading {
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: var(--elephant);
  margin: 0;
}

.blank-state-container-image {
  display: flex;
  justify-content: center;
}

.blank-state-container-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  width: 426px;
  color: var(--smoke700);
  margin: 4px 0;
}

.container {
  height: 100vh;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}

@media (max-device-width: 600px) {
  .container {
    height: 100vh;
    padding: 30px 0px;
    box-sizing: border-box;
  }
}
